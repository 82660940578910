import Link from 'next/link'
import ContentImage from 'components/display/ContentImage';
import Style from './RenderHero.module.scss'


function HeroImage({ 
    cta = [], 
    image = [], 
    title, 
    cloudinary_url,
    priority = false
}){
    return (
        <>
            {cta.length ? 
                <Link
                    href={cta[0]?.ctaURL ?? "#"}
                    title={cta[0]?.ctaTitle}
                    className={Style.link}
                >
                    <ContentImage 
                        img={image[0]} 
                        {...{ cloudinary_url, title, priority }}
                        className={Style.image} 
                        width={1400}
                        height={500}
                    />
                </Link>
            :
            
                <ContentImage 
                    img={image[0]} 
                    {...{ cloudinary_url, title, priority }}
                    className={Style.image} 
                    width={1400}
                    height={500}
                />
            }
        </>
    )
}


export default HeroImage;