import { LocalStorage, storageKeys } from 'services/LocalStorage.service';

export const getCart =  () => {
    const cartData =  LocalStorage.getStorage(storageKeys.cart);

    if (cartData?.line_items?.physical_items) {
        const cart = {
            id: cartData?.id,
            cart_amount: cartData?.cart_amount
        }

            // Cart Items to Nosto
            const cartItems = cartData?.line_items?.physical_items.map((item) => {
                return {
                    name: item.name,
                    priceCurrencyCode: "USD",
                    productId: item.product_id,
                    quantity: item.quantity,
                    skuId: item.sku,
                    unitPrice: item.list_price
                }
            })
            return {...cart, items:cartItems };

    } 
           else {
               return [];
    }

}