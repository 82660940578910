"use client"

/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react"
import { NostoContext } from "../context/NostoContext";
import { View404, ViewCart, ViewCategory, ViewFrontPage, ViewPage, ViewProduct, ViewSearch, ViewBlog, ViewOther } from "../NostoEvents"
import { GlobalContext } from "contexts/GlobalContext";
import { getCart } from "../helpers/GetCart";

const NostoEvent = {
    "View404": View404,
    "ViewBlog": ViewBlog,
    "ViewCart": ViewCart,
    "ViewPage": ViewPage,
    "ViewOther": ViewOther,
    "ViewSearch": ViewSearch,
    "ViewProduct": ViewProduct,
    "ViewCategory": ViewCategory,
    "ViewFrontPage": ViewFrontPage
};


function useNostoEventTracker({ event, placementIds=[], url, ...other }){
    const [ nostoState, dispatch ] = useContext(NostoContext)
    const [global, globalDispatch] = useContext(GlobalContext)
    const cartData = getCart();

    function callNostoEvent({ placement }) {
        NostoEvent[event].call(null, { 
            placementIds: typeof placement === "string" ? placement : placement.placementIds,
            categoryTarget: placement?.categoryTarget ?? null,
            categoryNameTaxonomy: placement?.categoryNameTaxonomy ?? null,
            ...other,
            cart:cartData,
            session: global?.thirdParty?.nostoSession,
            callback: (data) => {
                const obj = {};

                if( Array.isArray(data) ){
                    data.forEach(placement => {
                        obj[placement?.resultId] = {
                            ...placement
                        }
                    })
                    dispatch({
                        type: "setRecommendations",
                        data: obj
                    })
                }
                
            }
        })

    }


    useEffect(() => {
        if (event && placementIds && global?.thirdParty?.nostoSession) {
            const isLegacyPlacementArray = Array.isArray(placementIds) && placementIds.every(placement => typeof placement === "string")

            // if its just an array of placement ids, make 1 call
            if( isLegacyPlacementArray ){
                callNostoEvent({ placement: placementIds.join(",") })

            } else {
                // if we have an array of objects, make multiple calls
                placementIds.forEach(placement => {
                    callNostoEvent({ placement })
                })
            }
        }

    }, [ event, global?.thirdParty?.nostoSession, url ])

}



export default useNostoEventTracker