import PropTypes from 'prop-types';
import clsx from 'clsx';
import HeroImage from './HeroImage';
import Videro from './Videro';
import Style from './RenderHero.module.scss'
import utils from 'styles/globals/utils.module.scss';
import GridHero from './GridHero';
import Hotspots from 'components/display/ImageHotspot';


function RenderHero({ 
    backgroundColor,
    image, 
    title, 
    heroTitle,
    anchorId, 
    useGrid = false,
    paddingX,
    paddingY,
    marginY,
    className = "",
    cta,
    cloudinary_url,
    video,
    autoPlay,
    priority = false,
    tradeStatus,
    imageHotspot = null,
    isMobile,
    customer_group_id,
    isLazyLoad = false,
    desktop,
    mobile,
}) {
    
    const constructedCloudinaryUrl = desktop && mobile ? `desktop=${desktop}, mobile=${mobile}` : cloudinary_url;

    if (customer_group_id === 1 && tradeStatus === 'no') {
        return null;
    }

    return(
        <>
            {useGrid ? 
                <GridHero 
                    {...{
                        backgroundColor,
                        image, 
                        title, 
                        heroTitle,
                        anchorId, 
                        paddingX,
                        marginY,
                        className,
                        cta,
                        cloudinary_url: constructedCloudinaryUrl,
                        video,
                        autoPlay,
                    }}
                />
            :
                <>
                    {heroTitle && (
                        <h2 className={clsx(utils.fs_2, utils.text_center)}>
                            {heroTitle}
                        </h2>
                    )}

                    <figure 
                        className={clsx(
                            Style.block,
                            className,
                            backgroundColor && utils[`bg_${backgroundColor?.class}`],
                            paddingX && utils[`px_${paddingX}`],
                            paddingY && utils[`py_${paddingY}`],
                            marginY && utils[`my_${marginY}`],
                            constructedCloudinaryUrl && utils.pt_0
                        )} 
                        id={anchorId}
                    >
                        {video ? (
                            <Videro {...{ video, autoPlay }} />
                        ):(
                            <div style={{ position: 'relative' }}>
                                <HeroImage 
                                    {...{ 
                                        cta, 
                                        image, 
                                        title, 
                                        cloudinary_url: constructedCloudinaryUrl, 
                                        priority 
                                    }} 
                                />

                                {imageHotspot && (
                                    <Hotspots 
                                        isMobile={isMobile}  
                                        imageHotspot={imageHotspot}
                                    />
                                )}
                            </div>
                        )}
                    </figure> 
                </>
            }
        </>
    )
    

}




RenderHero.propTypes = {
    backgroundColor: PropTypes.object,
    image: PropTypes.array, 
    title: PropTypes.string, 
    anchorId: PropTypes.string, 
    useGrid: PropTypes.bool, 
    paddingX: PropTypes.string,
    paddingY: PropTypes.string,
    marginY: PropTypes.string,
    video: PropTypes.string,
    autoPlay: PropTypes.bool,
    hotspots: PropTypes.arrayOf(PropTypes.shape({
        position: PropTypes.shape({
            x: PropTypes.number.isRequired,
            y: PropTypes.number.isRequired
        }),
        content: PropTypes.node,
        onClick: PropTypes.func
    })),
}

export default RenderHero;