import Grid from 'components/layout/Grid';
import GridColumn from 'components/layout/GridColumn';
import clsx from 'clsx';
import HeroImage from './HeroImage';
import Videro from './Videro';

import Style from './RenderHero.module.scss'
import utils from 'styles/globals/utils.module.scss';


function GridHero({
    backgroundColor,
    image, 
    title, 
    heroTitle,
    anchorId, 
    paddingX,
    marginY,
    className = "",
    cta,
    cloudinary_url,
    video,
    autoPlay,
    priority    
}){
    return(
        <Grid 
            id={anchorId} 
            className={clsx(
                Style.block,
                className,
                backgroundColor && utils[`bg_${backgroundColor?.class}`],
                paddingX && utils[`px_${paddingX}`],
                utils.py_5,
                marginY && utils[`my_${marginY}`],
                !cloudinary_url && utils.pt_0
            )}
        >
            <GridColumn start={2} end={10} noWrap>
                {heroTitle && (
                    <h2 className={clsx(utils.fs_2, utils.text_center)}>
                        {heroTitle}
                    </h2>
                )}

                {video ? (
                    <Videro 
                        video={video}
                        autoPlay={autoPlay}
                    />

                ):(
                    <HeroImage 
                        {...{ cta, image, title, cloudinary_url, priority }} 
                    />
                )}
            </GridColumn>
        </Grid>
    )
}


export default GridHero;