import Style from './RenderHero.module.scss'
import PropTypes from 'prop-types';


function Videro({ video, autoPlay }) {
    const shouldAutoPlay = autoPlay === "true" || autoPlay === true;
    if (shouldAutoPlay) {
        return (
            <video className={Style.hero} autoPlay muted loop>
                <source src={video} />
            </video>
        );
    } else {
        return (
            <video className={Style.hero} controls>
                <source src={video} />
            </video>
        );
    }
}

Videro.propTypes = {
    autoPlay: PropTypes.bool
}


export default Videro;