"use client"

import { getPlacementIdsFromBlocks } from "./Nosto.helpers";
import useNostoEventTracker from "./hooks/useNostoEventTracker";


function NostoEventTracker({ blocks, slotIds = null, trackerObject }){
    const placements = blocks && !slotIds ? getPlacementIdsFromBlocks({ blocks }) : slotIds;

	useNostoEventTracker({
        ...trackerObject,
        placementIds: placements?.[0]?.placementIds ?? slotIds
    })

    return null
}

export default NostoEventTracker;