"use client"

import PropTypes from 'prop-types';
import RenderHero from "../RenderHero";
import {  useEffect } from "react";
import clsx from 'clsx';
import Style from './RenderDynamicHero.module.scss'
import utils from 'styles/globals/utils.module.scss';



function RenderDynamicHero({
    heroImage,
    heroLink,
    heading = null,
    subHeading = null,
    cloudinary_url,
    desktop,
    mobile
}) {


    const cta = heroLink && heroLink.length > 0 ? [{
        ctaURL: heroLink[0].linkUrl,
        ctaTitle: heroLink[0].altText || "Learn More"
    }] : [];

    return (

        <>
            { heading && (
                <>
                    <span>{heading}</span>
                    {subHeading}
                </>
            )}

            <RenderHero 
                image={heroImage}
                cta={cta}
                className={clsx(Style.block, utils.m_0)}
                title="authenteak.com featured special"
                priority
                cloudinary_url={cloudinary_url}
                desktop={desktop}
                mobile={mobile}
            />
        </>
    );
}


RenderDynamicHero.propTypes = {
    heroImage: PropTypes.array,
    cta: PropTypes.array,
    heading: PropTypes.string,
    subHeading: PropTypes.string
}


export default RenderDynamicHero;