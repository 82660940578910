
// merge all of the other placements with no custom category targets to make 1 call
function bulkMergePlacements(placements){
     const merged = {
        placementIds: "",
        categoryTarget: null,
        categoryNameTaxonomy: null
    };

    let mergedPlacements = ""

    placements.forEach(placement => {
        mergedPlacements += `${placement.placementIds.includes(",") ? placement.placementIds.split(",").map(id => id.trim()) : placement.placementIds },`
    })

    mergedPlacements = mergedPlacements.slice(0, -1)
    mergedPlacements = mergedPlacements.split(",").map(id => id)

    merged.placementIds = mergedPlacements;

    return merged;
}




export function getPlacementIdsFromBlocks({ blocks = [] }){
    if( Array.isArray(blocks) && blocks.length ){
        let slots = blocks.filter(block => Object.hasOwn(block, "placementId")).map(block => {
            return {
                placementIds:  block?.placementId,
                categoryNameTaxonomy: block?.categoryNameTaxonomy ?? null,
                categoryTarget: block?.categoryTarget ?? null,
                id: block.id
            }
        })        

        slots = slots.filter(id => id.placementIds !== null && id.placementIds !== undefined && id.placementIds !== "")

        // pull out the targeted placements
        const targetedPlacements = slots.filter(slot => !!slot.categoryTarget)

        // pull out the placements with no targets
        const placements = slots.filter(slot => !slot.categoryTarget)

        // merge the placements with no targets to make 1 call
        const merged = bulkMergePlacements(placements)

        return [
            ...targetedPlacements,
            merged
        ]    
    }
}
